import { BLACKLISTED_PROVIDERS } from "../lib/libraries";

const SITE_KEY =
  process.env.GATSBY_SITE_KEY || "6Lc9pb8mAAAAALDyNYCX7JfS9m_BWdVZp2e_f1eR";

export const loadReCaptcha = () => {
  const script = document.createElement("script");
  script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${SITE_KEY}`;
  document.body.appendChild(script);
};

export const getFormById = formId => {
  return fetch(`/api/getForm/${formId}`)
    .then(res => res.json())
    .then(data => {
      return data;
    })
    .catch(err => {
      console.error(err);
    });
};

const utmFields = parameters => {
  if (Object.keys(parameters).length === 0) return;
  Object.keys(parameters).forEach(name => {
    localStorage.setItem(name, parameters[name]);
    sessionStorage.setItem(name, parameters[name]);
  });
};

export const getParam = p => {
  if (typeof window === "undefined") return;
  const match = RegExp(`[?&]${p}=([^&]*)`).exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

const getCookie = cname => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const getParamFromLocalStorage = name => {
  const UTM_VALUES = localStorage.getItem("UTM_VALUES");
  const match = RegExp(`[?&]${name}=([^&]*)`).exec(UTM_VALUES);
  if (!match) return "";
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

const getExpiryRecord = value => {
  const expiryPeriod = 90 * 24 * 60 * 60 * 1000;

  const expiryDate = new Date().getTime() + expiryPeriod;
  return {
    value,
    expiryDate,
  };
};

function addGclid() {
  const gclidParam = getParam("gclid") || getParamFromLocalStorage("gclid");
  const gclidFormField = document.querySelector('input[name="gclid"');
  let gclidRecord = null;

  const gclsrcParam = getParam("gclsrc");
  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf("aw") !== -1;

  if (!gclidParam) return;

  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam);
    localStorage.setItem("gclid", JSON.stringify(gclidRecord));
  }

  const gclid = gclidRecord || JSON.parse(localStorage.getItem("gclid"));
  const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;

  if (gclidFormField && isGclidValid) {
    gclidFormField.value = gclid.value;
  }
}

export const removeItemFromLocalStorage = name => {
  if (!localStorage.getItem(name)) return;
  localStorage.removeItem(name);
};

export const getItemsfromLocalStorage = name => {
  const localItem = localStorage.getItem(name);
  if (name === "gclid" && localItem) return JSON.parse(localItem).value;
  return localItem;
};

export const handleInitialStateStorage = (field, parameters) => {
  if (field.name === "gclid") return;
  if (field.name === "branch") return;
  if (field.name === "url_slug") {
    return localStorage.setItem(field.name, window && window.location.href);
  }

  addGclid();
  utmFields(parameters);
  localStorage.setItem(field.name, handleInjectItemValue(field));
};

const handleInjectItemValue = field => {
  if (!field.hidden) return "";

  const valueMap = {
    document_referrer: document.referrer,
    sfdccampaignid: field.defaultValue,
    display_chilipiper: field.selectedOptions[0],
    form_name: field.defaultValue,
    session_start_page: getCookie("session_start_page"),
  };

  if (field.name in valueMap) {
    return valueMap[field.name];
  }

  const paramValue = getParam(field.name);
  const localStorageValue = getParamFromLocalStorage(field.name);

  return paramValue || localStorageValue || "";
};

const handleFinalStateStorage = formState => {
  Object.keys(formState).forEach(key => {
    if (key === "gclid") return;
    if (formState[key].value === "") return localStorage.removeItem(key);
    localStorage.setItem(key, formState[key].value);
  });
};

const cleanFormState = formState => {
  const dirtyState = Object.keys(formState).filter(
    key => formState[key].value === null
  );
  const cleanState = formState;
  dirtyState.map(key => {
    delete cleanState[key];
  });

  return cleanState;
};

export const submitForm = async (
  formId,
  formState,
  pageUri,
  pageName,
  consent
) => {
  handleFinalStateStorage(formState);
  const cleanState = await cleanFormState(formState);
  const payload = await handlePayLoad(cleanState, pageUri, pageName, consent);
  const token = await getToken(SITE_KEY, "submit");
  return fetch(`/api/formSubmit/${formId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      formData: payload,
      recaptchaUserResponse: token,
    }),
  })
    .then(res => res.json())
    .then(data => {
      return data.submitFormResponse;
    })
    .catch(err => {
      console.error(err);
    });
};

const getToken = async (sitekey, action) => {
  const token =
    (await window) &&
    window.grecaptcha.execute(sitekey, { action }).then(token => {
      return token;
    });

  return token;
};

const getIpAdress = () => {
  return fetch("/api/ipInfo/getUserGeoLocation")
    .then(res => res.json())
    .then(data => {
      return data.ip;
    })
    .catch(err => {
      console.error(err);
    });
};

const handlePayLoad = async (formState, pageUri, pageName, consent) => {
  const ipAddress = await getIpAdress();
  const hutkFromCookie = getCookie("hubspotutk");
  const sfdcCampaignId = localStorage.getItem("sfdccampaignid");
  localStorage.setItem("ip_address", ipAddress);
  const fields = Object.keys(formState).map(key => ({
    objectTypeId: formState[key].objectTypeId,
    name: key,
    value: formState[key].value,
  }));
  const returnObject = {
    submittedAt: Date.now(),
    fields: [...fields],
    context: {
      pageUri,
      pageName,
      ipAddress,
      ...(hutkFromCookie && { hutk: hutkFromCookie }),
      ...(sfdcCampaignId && { sfdcCampaignId: sfdcCampaignId }),
    },
    ...(consent && {
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: consent.processingConsentCheckboxLabel,
          communications: [
            {
              value: true,
              subscriptionTypeId:
                consent.communicationConsentCheckboxes[0].communicationTypeId,
              text: consent.processingConsentCheckboxLabel,
            },
          ],
        },
      },
    }),
  };

  return returnObject;
};

const extractEmailProvider = email => {
  const atIndex = email.indexOf("@");
  if (atIndex === -1) return null;
  const provider = email.slice(atIndex + 1);
  return provider.toLowerCase();
};

export const validateEmail = (name, state, value) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const passed = emailRegex.test(value);
  const provider = extractEmailProvider(value);
  const valid =
    BLACKLISTED_PROVIDERS.every(item => item !== provider) && passed;
  const result = {
    ...state,
    [name]: {
      valid,
      // eslint-disable-next-line no-nested-ternary
      message: valid
        ? ""
        : provider
        ? `Please enter your business email address. This form does not accept addresses from ${provider}`
        : "Please enter your business email address.",
    },
  };
  return result;
};

export const validatePhone = (name, state, value) => {
  const telRegex = /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;
  const valid = telRegex.test(value);
  const result = {
    ...state,
    [name]: {
      valid,
      message: valid ? "" : "Must be a valid phone number.",
    },
  };
  return result;
};

export const validateText = (name, state, value) => {
  const valid = value.length >= 2 && !!/\S/.test(value);
  const result = {
    ...state,
    [name]: {
      valid,
      message: valid ? "" : "Please complete this required field.",
    },
  };
  return result;
};

export const handleInputType = type => {
  switch (type) {
    case "text":
      return "text";
    case "phonenumber":
      return "tel";
    default:
      break;
  }
};

export const handleBooleanString = booleanString => {
  if (booleanString === "true") {
    return true;
  } else {
    return false;
  }
};

export const sendCustomGtagEvent = eventName => {
  if (typeof gtag === "undefined") return;
  gtag("event", eventName);
};

export const sendPostHogEvent = event => {
  if (typeof posthog === "undefined") return;
  const currentUrl = window.location.href;
  posthog.capture(event, "$pageview", {
    $current_url: currentUrl,
  });
};
